@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 540px) { @content };
    }

    @if $breakpoint == tab-port {
        @media only screen and (max-width: 720px) { @content };
    }

    @if $breakpoint == tab-land {
        @media only screen and (max-width: 960px) { @content };
    }

    @if $breakpoint == desktop {
        @media only screen and (max-width: 1140px) { @content };
    }
    
    @if $breakpoint == big-desktop {
        @media only screen and (max-width: 1320px) { @content };
    }
}
