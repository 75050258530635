.nav {
    &__container {
        padding: 2rem;
        margin: 2.5rem 0 5rem 0;
        border-radius: 1rem;
        box-shadow: .5rem .5rem 4rem rgba(#000, .2);

        display: flex;
    }

    &__logo {
        flex-basis: 50%;
        display: flex;
        align-items: center;
    }

    &__nav {
        flex-basis: 50%;
        display: flex;
        justify-content: end;
    }

    &__list {
        list-style: none;
    }

    &__item {
        display: inline-block;
        height: 100%;

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    &__link {
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__link,
    &__logo-link {
        &:link,
        &:visited {
            color: inherit;
            text-decoration: none;
        }
    }

    &__icon {
        width: 22px;
        height: 22px;
        stroke-width: 2;
        stroke: currentColor;
    }
}
