$color-primary: #e9ba30;
$color-secondary: #5e380f;

$color-font-primary: #333333;
$color-font-secondary: #666666;

$color-black: #000000;
$color-white: #FFFFFF;

$color-danger: #f5365c;
$color-success: #2dce89;

$font-size-default: 1.6rem;
$font-size-lead: 1.8rem;
