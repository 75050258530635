@use '../abstracts' as *;

body {
    font-family: $font-family-sans-serif;
    line-height: 1.7;
}

.lead {
    font-size: $font-size-lead;
}
